<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.UserId"
        placeholder="UserId"
        style="width: 180px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.Email"
        placeholder="Email"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.SubAccUserId"
        placeholder="SubAccUserId"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.FirstName"
        placeholder="FirstName"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.TokenCode"
        placeholder="TokenCode"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="handleFilter"
        >Search</el-button
      >
      <span class="filter-item">
        <download-excel
          :fields="json_fields"
          :fetch="handleExport"
          type="xls"
          name="WalletBalance.xls"
        >
        <el-button
          type="primary"
          icon="el-icon-download"
          size="small"
          :loading="exportLoading"
          @click="handleFilter"
        >Export</el-button>
        </download-excel>
      </span>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="Email" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.User.Email }}
        </template>
      </el-table-column>
      <el-table-column label="Token Code" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.TokenCode }}
        </template>
      </el-table-column>
      <el-table-column label="Amount" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.Amount }}
        </template>
      </el-table-column>
      <el-table-column label="Freeze Amount" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.FreezeAmount }}
        </template>
      </el-table-column>
      <el-table-column label="Wallet Type" min-width="110" align="center">
        <template slot-scope="scope">
          {{ getWalletTypeName(scope.row.WalletType) }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        UserId: '',
        Email: '',
        SubAccUserId: '',
        FirstName: '',
        TokenCode: '',
      },
      exportLoading: false,
      json_fields: {
        Email: 'User.Email',
        TokenCode: 'TokenCode',
        Amount: 'Amount',
        FreezeAmount: 'FreezeAmount',
        WalletType: {
          field: "WalletType",
          callback: (value) => {
            return this.getWalletTypeName(value);
          },
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/admin/report/walletBalance",
        method: "get",
        params: this.listQuery
      }).then(response => {
        console.log(response.data);
        this.list = response.data.Data;
        this.total = response.data.Total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getWalletTypeName(val) {
      if (val == 10) return "CA";
      if (val == 30) return "TRADERPOT";
      if (val == 40) return "REFERRALPOT";
      if (val == 50) return "BDPOT";
      if (val == 60) return "CHALLENGEPOOL";
      if (val == 70) return "AMBASSADORUSDT";
      if (val == 80) return "RECLAIM_POT";
      if (val == 90) return "RECLAIM_USDT";
    },
    handleExport() {
      this.exportLoading = true;
      var query = JSON.parse(JSON.stringify(this.listQuery));
      query.limit = 99999999999999;
      return request({
        url: "/api/admin/report/walletBalance",
        method: "get",
        params: query,
      })
      .then((result) => {
        var json_data = result.data.Data;
        return json_data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.exportLoading = false;
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
